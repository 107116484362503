
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchTable from '@/components/wizard/SearchTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next';
import { ElBadge } from 'element-plus';

export default defineComponent({
  name: 'add-reassessment-claim-2',
  components: {
    Datatable,
    SearchTable,
  },
  data() {
    return {
      bill: {
        course_type: '',
        tranche_id: '',
        entity_id: '',
        entity_contract_id: '',
        start_date: '',
        training_institute_id: '',
        course_info_id: '',
        batch_info_id: '',
        bill_sequence_no: '',
      },
      courseTypes: [],
      selectedBatch: [],
      tranches: [],
      entityInfos: [],
      contractInfos: [],
      institutes: [],
      courses: [],
      batches: [],
      BatchList: [],
      billList: [],
      allBill: [
        {
          trainee_count: '',
          bill_amount: '',
          contribution_percentage: '',
          contribution_amount: '',
          net_payable: '',
        },
      ],

      claimBillkey: 0,
      claimBillBatchkey: 0,
      tolatTrainee: 0,
      tolatBillAmount: 0,
      contributionPay: 0,
      totalContributionAmount: 0,
      totalPayableAmount: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showBillInfo: false,
      showBillList: false,
      showBatchList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getCourseTypes();
    await this.getTranche();
    // await this.getAssociation();
    this.bill.start_date = moment().format('YYYY-MM-DD');
    this.emitter.on('claim-2-reassessment-bill-update', async () => {
      await this.getBillList();
    });
    this.emitter.on('claim-2-reassessment-bill-batch-list-update', async () => {
      await this.eligibleBatchList();
    });
  },
  methods: {
    async getCourseTypes() {
      this.load = true;
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseTypes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityContract() {
      this.billListShow();
      this.load = true;
      await ApiService.get(
        'entity/contract/list?entity_info_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id
      )
        .then((response) => {
          this.contractInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBillSequenceNo() {
      this.load = true;
      await ApiService.get(
        'bill/claim_2/reassessment/sequence_no?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&entity_contract_id=' +
          this.bill.entity_contract_id
      )
        .then((response) => {
          this.bill.bill_sequence_no = response.data.data;
          this.load = false;
          this.eligibleBatchList();
          this.emitter.emit('claim-2-reassessment-bill-update', true);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async eligibleBatchList() {
      this.load = true;
      await ApiService.get(
        'bill/claim_2/reassessment/eligible_batch?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&course_type=' +
          this.bill.course_type
      )
        .then((response) => {
          this.BatchList = response.data.data;
          this.showBatchList = true;
          this.claimBillBatchkey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async trainingInstitute() {
      await ApiService.get('institute/list?entity_id=' + this.bill.entity_id)
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      await ApiService.get(
        'course/list?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&training_institute_id=' +
          this.bill.training_institute_id +
          '&course_type=' +
          this.bill.course_type
      )
        .then((response) => {
          this.courses = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBillList() {
      this.tolatTrainee = 0;
      this.tolatBillAmount = 0;
      this.contributionPay = 0;
      this.totalContributionAmount = 0;
      this.totalPayableAmount = 0;
      await ApiService.get(
        'bill/claim_2/reassessment/list?entity_id=' +
          this.bill.entity_id +
          '&tranche_id=' +
          this.bill.tranche_id +
          '&course_type=' +
          this.bill.course_type +
          '&entity_contract_id=' +
          this.bill.entity_contract_id +
          '&active_status=1'
      )
        .then((response) => {
          this.billList = response.data.data;
          this.allBill = response.data.data;
          this.allBill.forEach((el) => {
            this.tolatTrainee += ~~el.trainee_count;
            this.tolatBillAmount += ~~el.bill_amount;
            this.contributionPay = ~~el.contribution_percentage;
            this.totalContributionAmount += parseFloat(el.contribution_amount);
            this.totalPayableAmount += parseFloat(el.net_payable);
          });
          this.claimBillkey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async addAllToClaim() {
      if (this.selectedBatch.length === 0) {
        Swal.fire({
          title: 'Error!',
          html: 'Please select a batch.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        }).then(() => {
          this.loading = false;
        });
      } else {
        Swal.fire({
          title: 'Are you sure to send all batch in claim ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Submitted!',
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(this.selectedBatch);
            let data = {
              tranche_id: this.bill.tranche_id,
              entity_id: this.bill.entity_id,
              entity_contract_id: this.bill.entity_contract_id,
              course_type: this.bill.course_type,
              bill_sequence_no: this.bill.bill_sequence_no,
              start_date: this.bill.start_date,
              batch_list: this.selectedBatch,
            };
            this.loading = true;
            ApiService.post('bill/claim_2/reassessment/save', data)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.emitter.emit('claim-2-reassessment-bill-update', true);
                  this.emitter.emit(
                    'claim-2-reassessment-bill-batch-list-update',
                    true
                  );
                  Swal.fire({
                    title: 'Success!',
                    text: response.data.data,
                    icon: 'success',
                    buttonsStyling: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  }).then(() => {});
                } else {
                  let err = '';
                  for (const field of Object.keys(response.data.errors)) {
                    err += response.data.errors[field][0] + '<br>';
                  }
                  Swal.fire({
                    title: 'Please check all the required field',
                    html: err,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: 'Close',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  });
                }
              })
              .catch(({ response }) => {
                this.loading = false;
                Swal.fire({
                  title: 'Unknown error',
                  html: response.data.error,
                  icon: 'error',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                });
                console.log(response);
              });
          } else {
            this.loading = false;
          }
        });
      }
    },

    async submitBill() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      user_id =  user_id.id;

      let data = {
        tranche_id: this.bill.tranche_id,
        entity_id: this.bill.entity_id,
        entity_contract_id: this.bill.entity_contract_id,
        course_type: this.bill.course_type,
        bill_sequence_no: this.bill.bill_sequence_no,
        start_date: this.bill.start_date,
        user_id: user_id,
      };

      this.loading = true;
      await ApiService.post('bill/claim_2/reassessment/bill_submit', data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit('claim-2-reassessment-bill-update', true);
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {});
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    confirmSubmitBill() {
      Swal.fire({
        title: "You can't edit bill after submit. Are you sure to submit ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Submitted!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.submitBill();
        }
      });
    },
    Delete(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('bill/claim_2/reassessment/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('claim-2-reassessment-bill-update', true);
              this.eligibleBatchList();
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    moment() {
      return moment();
    },
    billListShow() {
      this.showBillInfo = true;
      this.showBillList = false;
      this.showBatchList = false;
    },
  },
  setup() {},
});
